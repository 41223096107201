export const GET_BRANDS_API_CALL = 'Get-All-Brands-Api-Call'
export const GET_BRANDS_API_RESPONSE = 'Get-All-Brands-Api-Response'
export const GET_ALL_UNITS_API_CALL = "Get-All-Units-Api-Call"
export const GET_ALL_UNITS_API_RESPONSE = "Get-All-Units-Api-Response"
export const GET_PRODUCT_SIZE_API_CALL = "Get-All-Product-Size-Api-Call"
export const GET_PRODUCT_SIZE_API_RESPONSE = "Get-All-Products-Size-Api-Response"
export const ADD_ORDER_ITEMS_API_CALL = 'Get-Order-Items-Api-Call'
export const ADD_ORDER_ITEMS_API_RESPONSE = 'Get-Order-Items-Api-Response'
export const GET_SUB_CATEGORY_BASED_ON_CATEGORY_API_CALL = 'Get-SubCategory-Based-On-Parent-Api-Call'
export const GET_SUB_CATEGORY_BASED_ON_CATEGORY_API_RESPONSE = 'Get-SubCategory-Based-On-Parent-Api-Response'
export const GET_ALL_ACTIVE_PRODUCTS_API_CALL = 'Get-All-Active-Products-Api-Call'
export const GET_ALL_ACTIVE_PRODUCTS_API_RESPONSE = 'Get-All-Active-Products-Api-Response'




export const RESET_PAYMENT_STATUS_CODE = 'Reset-Payment-Status-Code'