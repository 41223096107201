import AxiosConfig from '../../WebService/AxiosConfig';
import config from '../../WebService/Config';
import axios from 'axios'

export function Category(payload) {

   return AxiosConfig.post(`/category/getCategory`,
      {
      }, {
      params: {
         categoryName: payload
      }
   }

   ).then(response => response).catch(error => {
      console.log(error, "catch")
      return error
   });

}

// export  function SubCategory(payload) {


export async function SubCategory(payload) {
   return await AxiosConfig.post('/subCategory/getAllSubCategory', {
   }, {
      params: {
         subCategory: payload
      }
   })
}

export async function SubCategoryBasedOnParent(payload) {
   return await AxiosConfig.post('/subCategory/getSubCategory', {
   }, {
      params: {
         parentCategory: payload
      }
   })
}


export function getAllBrands(payloads) {
   let params = {}
   if (payloads != undefined || payloads != null) {
      params['brand'] = payloads
   }
   return AxiosConfig.post(`/brand/getBrand`, {}, {
      params: params
   }).then(response => response).catch(error => {
      console.log(error, "catch")
      return error
   });
}
//    }



export async function AddProductDetails(payload) {
   return await AxiosConfig.post('/products/addProduct', payload, {
      data: payload
   })
}





export async function AddBrand(payload) {
   return await AxiosConfig.post('/brand/insertBrand', payload, {
      data: payload
   })
}

export async function GetProduct() {
   return await AxiosConfig.post('/products/getProducts', {
   })
}

export async function getAllUnitsCall() {
   return await AxiosConfig.post('/unit/getUnit')
}

export async function getFreebie(payload) {
   return await AxiosConfig.post('/products/getProductsByBarcodeOrName', {
   }, {
      params: {
         inputText: payload
      }
   })
}

//   getProductDetailsbyid
export async function getProductDetailsbyid(productName) {
   return await AxiosConfig.post('/products/getProductsByBarcodeOrName', {
   }, {
      params: {
         inputText: productName
      }
   });
}

export const getProductSizes = async () => {
   return await AxiosConfig.post('/productSize/getProductSize')
}

export const getActiveProductsCall = async () => {
   return await AxiosConfig.post('/products/getProductsWithProductPrice')
}